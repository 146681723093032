<script setup>
/******************************************************************************
 * PageComponent: MeterRegister (Zählwerk) implausible reason
 *****************************************************************************/
import { computed } from "vue";
import FormRow from "Components/Form/FormRow.vue";
import FormSelect from "Components/Form/FormSelect.vue";
import FormError from "Components/Form/FormError.vue";
import { useReadingStore } from "Stores/reading";
import { isDevelopment } from "@/handlers/useEnvironment";
const data = useReadingStore();
const props = defineProps({
    meterRegisterId: {
        type: String,
        required: true,
    },
});
const selectedReason = computed({
    get: () => data.getImplausibleReason(props.meterRegisterId),
    set: (value) => {
        data.setImplausibleReason(props.meterRegisterId, value);
    },
});
const options = [
    { label: "Längere Abwesenheit", value: "Längere Abwesenheit" },
    { label: "Neues Gerät", value: "Neues Gerät" },
    { label: "Sonstiges (bitte angeben)", value: "Sonstiges" },
];
const onChange = (value) => {
    selectedReason.value = value;
    isDevelopment &&
        console.log(
            `implausible reason of ${props.meterRegisterId} changed to "${value}"`
        );
};
</script>

<template>
    <form-row
        :top="0.5"
        :required="true"
        label="Grund für unplausiblen Zählerstand"
        :for-id="`implausibleReason-${meterRegisterId}`"
    >
        <template #texttop>
            <form-error
                v-if="!selectedReason"
                error-text="Der Zählerstand scheint unplausibel zu sein. Bitte wählen Sie einen Grund für den unplausiblen Zählerstand aus oder beenden Sie die Eingabe des Zählerstandes."
                :bottom="0.2"
            />
        </template>
        <template #input>
            <form-select
                :options="options"
                :selected="selectedReason"
                :for-id="`implausibleReason-${meterRegisterId}`"
                @change="onChange"
            />
        </template>
    </form-row>
</template>
