/******************************************************************************
 * pinia ACTIONS for readingStore
 *****************************************************************************/
import { isDevelopment } from "@/handlers/useEnvironment";
import { useAppStore } from "../app";
import { useNavigationStore } from "../navigation";
import { ERROR_MESSAGES } from "@/config";

export const actions = {
    // @function set date and reason type to global
    setDateAndReasonTypGlobal() {
        isDevelopment && console.log("set dateAndReasonType to global");
        this.dateAndReasonType = "global";
    },
    // @function set date and reason type to individual
    setDateAndReasonTypIndividual() {
        isDevelopment && console.log("set dateAndReasonType to individual");
        this.dateAndReasonType = "individual";
    },
    /**
     * @function set reading value of meterRegister
     * @param {String} meterRegisterId
     * @param {String} value
     */
    setReadingValue(meterRegisterId, value) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.meterReadingValue = value;
            }
            return reading;
        });
    },
    /**
     * @function set implausibleReason of meterRegister
     * @param {String} meterRegisterId
     * @param {String} value
     */
    setImplausibleReason(meterRegisterId, value) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.implausibleReason = value;
            }
            return reading;
        });
    },
    /**
     * @function set implausibleReason of meterRegister
     * @param {String} meterRegisterId
     * @param {String} value
     */
    setImplausibleReasonOther(meterRegisterId, value) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.implausibleReasonOther = value;
            }
            return reading;
        });
    },
    /**
     * @function set reading image progress
     * @param {String} meterRegisterId
     * @param {Number} percentage
     */
    setImageProgress(meterRegisterId, percentage) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.image.progress = percentage;
            }
            return reading;
        });
    },
    /**
     * @function set reading image errors
     * @param {String} meterRegisterId
     * @param {array} errors
     */
    setImageErrors(meterRegisterId, errors) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.image.errors = errors;
            }
            return reading;
        });
    },
    /**
     * @function set reading image errors
     * @param {String} meterRegisterId
     * @param {String} filename
     */
    setImageFilename(meterRegisterId, filename) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.image.originalFileName = filename;
            }
            return reading;
        });
    },
    /**
     * @function set reading image errors
     * @param {String} meterRegisterId
     * @param {String} filePath
     * @param {String} thumb - base64 encoded thumbnail
     */
    setImagePaths(meterRegisterId, filePath, thumb) {
        this.readings = this.readings.map((reading) => {
            if (reading.meterRegisterId === meterRegisterId) {
                reading.image.filePath = filePath;
                reading.image.thumb = thumb;
            }
            return reading;
        });
    },
    /**
     * @function set customer contact data
     * @param {String} customerId
     * @param {String} what - key that should be changed
     * @param {String} value - what should be changed
     */
    setContactData(customerId, what, value) {
        this.customers = this.customers.map((customer) => {
            if (customer.customerId === customerId) {
                customer.contactData[what] = value;
            }
            return customer;
        });
    },
    /**
     * @function set customer global contact data error
     * @param {String} customerId
     * @param {String} error - the customer error message
     */
    setCustomerError(customerId, error) {
        this.customers = this.customers.map((customer) => {
            if (customer.customerId === customerId) {
                customer.contactError = error;
            }
            return customer;
        });
    },
    /**
     * @function set customer contact data errors
     * @param {String} customerId
     * @param {String} channel
     * @param {String} error - the customer error message
     */
    setCustomerContactError(customerId, channel, error) {
        this.customers = this.customers.map((customer) => {
            if (customer.customerId === customerId) {
                customer.contactErrors[channel] = error;
            }
            return customer;
        });
    },
    /**
     * @function clear all customer contact errors
     * @param {String} customerId
     */
    resetCustomerContactErrors(customerId) {
        this.customers = this.customers.map((customer) => {
            if (customer.customerId === customerId) {
                customer.contactErrors = {};
            }
            return customer;
        });
    },
    /**
     * @function set customer contact data done
     * @param {String} customerId
     * @param {Array} channels
     */
    addCustomerContactDone(customerId, channels) {
        this.customers = this.customers.map((customer) => {
            if (customer.customerId === customerId) {
                channels.forEach((channel) => {
                    if (!customer.contactDone.includes(channel)) {
                        customer.contactDone.push(channel);
                    }
                });
            }
            return customer;
        });
    },
    /**
     * @function set "queuedReading"
     * @param {boolean} status
     */
    queueReadings(status) {
        this.queuedReading = status;
    },

    /**************************************************************************
     * axios actions
     *************************************************************************/

    /**
     * @function upload image
     * @param {String} customerId
     * @param {String} meterRegisterId
     * @param {Object} fileObj
     */
    uploadImage(customerId, meterRegisterId, fileObj) {
        isDevelopment && console.log(`sending "${fileObj.name}" to server`);
        const formData = new FormData();
        const errorTime = 1000 * 10; // zeit in sekunden die eine fehlermeldung angezeigt wird.
        const setImageProgress = this.setImageProgress;
        const config = {
            onUploadProgress: function (ev) {
                let percentCompleted = Math.round((ev.loaded * 100) / ev.total);
                console.log("upload progress ", percentCompleted);
                setImageProgress(meterRegisterId, percentCompleted);
            },
        };
        formData.append("image", fileObj);
        formData.append("customerId", customerId);
        formData.append("meterRegisterId", meterRegisterId);
        // reset everything
        this.setImageErrors(meterRegisterId, []);
        this.setImageFilename(meterRegisterId, "");
        this.setImageProgress(meterRegisterId, 0);
        this.setImagePaths(meterRegisterId, "", "");
        this.requesting = true;
        window.axios
            .post("/api/image/upload", formData, config)
            .then((response) => {
                // error handling
                if (
                    response.data.status === "error" &&
                    response.data.errors.length > 0
                ) {
                    this.setImageErrors(meterRegisterId, response.data.errors);
                    window.setTimeout(() => {
                        this.setImageErrors(meterRegisterId, []);
                    }, errorTime);
                }
                // handling successful upload
                else if (response.data.status === "ok") {
                    this.setImageProgress(meterRegisterId, 100);
                    this.setImageErrors(meterRegisterId, []);
                    this.setImageFilename(meterRegisterId, fileObj.name);
                    this.setImagePaths(
                        meterRegisterId,
                        response.data.filePath,
                        response.data.thumb
                    );
                }
            })
            .catch((error) => {
                console.error("error", error, error.status);
                this.setImageFilename(meterRegisterId, "");
                if (error.response.status === 413) {
                    // 413 = request zu groß für nginx/php
                    this.setImageErrors(meterRegisterId, [
                        "Die Datei ist zu groß um vom Server verarbeitet zu werden.",
                    ]);
                } else {
                    // mainly 500s. server can't reach DB, tables gone etc.
                    this.setImageErrors(meterRegisterId, [
                        "Ihre Anfrage konnte nicht verarbeitet werden." +
                            " Bitte versuchen Sie es später noch einmal.",
                    ]);
                }
                window.setTimeout(() => {
                    this.setImageErrors(meterRegisterId, [[]]);
                }, errorTime);
            })
            .finally(() => {
                this.requesting = false;
                this.setImageProgress(meterRegisterId, 0);
            });
    },

    /**
     * @function delete uploaded image
     * @param {String} customerId
     * @param {String} meterRegisterId
     * @param {String} filePath
     */
    deleteImage(customerId, meterRegisterId, filePath) {
        isDevelopment &&
            console.log(
                `deleting image for customerId=${customerId}, meterRegisterId=${meterRegisterId}`
            );
        const errorTime = 1000 * 10; // zeit in sekunden die eine fehlermeldung angezeigt wird.
        const appStore = useAppStore();
        appStore.requesting = true;
        window.axios
            .post("/api/image/delete", {
                customerId,
                meterRegisterId,
                filePath,
            })
            .then((response) => {
                // error handling
                if (
                    response.data.status === "error" &&
                    response.data.errors.length > 0
                ) {
                    this.setImageErrors(meterRegisterId, response.data.errors);
                    window.setTimeout(() => {
                        this.setImageErrors(meterRegisterId, []);
                    }, errorTime);
                }
                // handling successful upload
                else if (response.data.status === "ok") {
                    isDevelopment && console.log("deletion successful.");
                    this.setImageErrors(meterRegisterId, []);
                    this.setImageFilename(meterRegisterId, "");
                    this.setImageProgress(meterRegisterId, 0);
                    this.setImagePaths(meterRegisterId, "", "");
                }
            })
            .catch((error) => {
                console.error("deletion failed", error);
                this.setImageErrors(meterRegisterId, [
                    [
                        "Ihre Anfrage konnte nicht verarbeitet werden." +
                            " Bitte versuchen Sie es später noch einmal.",
                    ],
                ]);
            })
            .finally(() => {
                appStore.requesting = false;
            });
    },

    /**
     * @function send readings to server
     * @param {Object} router
     */
    submitReadings(router) {
        const navigationStore = useNavigationStore();
        const appStore = useAppStore();
        const data = [];
        this.readings.forEach((reading) => {
            if (reading.meterReadingValue.length > 0) {
                const meterRegister = this.getMeterRegisterData(
                    reading.meterRegisterId
                );
                const customer = this.getCustomerData(meterRegister.customerId);
                data.push({
                    clientId: customer.clientId,
                    customerId: customer.customerId,
                    customerNumber: customer.customerNo,
                    customerName: this.getCustomerString(customer.customerId),
                    implausibleReason: reading.implausibleReason,
                    implausibleReasonOther: reading.implausibleReasonOther,
                    meterLocation: this.getMeterAdress(reading.meterNumber),
                    meterMediaType: meterRegister.meterMediaType,
                    meterUnit: meterRegister.meterUnit,
                    meterNumber: reading.meterNumber,
                    readingValue: parseFloat(reading.meterReadingValue),
                    lastReadingValue: meterRegister.lastReadingValue,
                    lastReadingDate: meterRegister.lastReadingDate,
                    meterRegisterId: reading.meterRegisterId,
                    meterRegisterNumber: reading.meterRegisterNumber,
                    meteringCode: reading.meteringCode,
                    obis: meterRegister.obis,
                    postCommaDigits: meterRegister.postCommaDigits,
                    description: meterRegister.description,
                    readingDate:
                        this.dateAndReasonType === "global"
                            ? this.globalReadingDate
                            : reading.readingDate,
                    readingReason:
                        this.dateAndReasonType === "global"
                            ? this.globalReadingReason
                            : reading.readingReason,
                    filePath: reading.image.filePath,
                    fileName: reading.image.originalFileName,
                });
            }
        });
        isDevelopment && console.log("submittitng reading", data);
        appStore.requesting = true;
        this.queueReadings(false);
        window.axios
            .post("/api/submit", { readings: data })
            .then((response) => {
                if (
                    response.status === 200 &&
                    response.data.message === "ok" &&
                    response.data.print
                ) {
                    this.error = "";
                    this.printFile = response.data.print;
                    navigationStore.changeStatus("Auth", "done");
                    navigationStore.changeStatus("MeterReading", "locked");
                    navigationStore.changeStatus("Confirm", "locked");
                    navigationStore.changeStatus("Success", "done");
                    router.push({ name: "Success" });
                } else {
                    console.error(response.data);
                    this.error = ERROR_MESSAGES.error500;
                }
            })
            .catch((error) => {
                console.error("submit failed", error);
                switch (error.response.status) {
                    case 429:
                        this.error = ERROR_MESSAGES.error429;
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        this.error = ERROR_MESSAGES.error500;
                        break;
                }
            })
            .finally(() => {
                appStore.requesting = false;
            });
    },

    /**
     * @function submit contact data to API
     * @param {String} customerId
     * @param {Number} clientId
     * @param {String} customerNo
     */
    submitContactData(customerId, clientId, customerNo) {
        const data = {
            customerId,
            clientId,
            customerNo,
            ...this.getCustomerData(customerId).contactData,
        };
        isDevelopment && console.log("submittitng contact data", data);
        //const errorTime = 1000 * 10; // zeit in sekunden die eine fehlermeldung angezeigt wird.
        const appStore = useAppStore();
        appStore.requesting = true;
        this.setCustomerError(customerId, "");
        this.resetCustomerContactErrors(customerId);
        window.axios
            .post("/api/contactdata", data)
            .then((response) => {
                if (response.status === 200 && response.data.message === "ok") {
                    this.addCustomerContactDone(customerId, response.data.done);
                } else if (response.data.error) {
                    console.error(response.data);
                    this.setCustomerError(customerId, response.data.error);
                } else if (response.data.errors) {
                    isDevelopment &&
                        console.warn(
                            "there are field errors!",
                            response.data.errors
                        );
                    for (const field in response.data.errors) {
                        this.setCustomerContactError(
                            customerId,
                            field,
                            response.data.errors[field]
                        );
                    }
                }
            })
            .catch((error) => {
                console.error("submit failed", error);
                switch (error.response.status) {
                    case 429:
                        this.setCustomerError(
                            customerId,
                            ERROR_MESSAGES.error429
                        );
                        break;
                    default: // mainly 500s. server can't reach DB, tables gone etc.
                        this.setCustomerError(
                            customerId,
                            ERROR_MESSAGES.error500
                        );
                        break;
                }
            })
            .finally(() => {
                appStore.requesting = false;
            });
    },
};
